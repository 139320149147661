<div class="e2e-create-folder-dialog">
  <div class="create-folder-header">
    <h2 mat-dialog-title>
      New Folder
    </h2>
    <button mat-icon-button mat-dialog-close class="dialog-close">
        <i class="material-icons">
          close
        </i>
      </button>
  </div>
  <mat-dialog-content>
    <form id="createFolderDialog" [formGroup]="createFolderDialog">
      <mat-form-field>
        <input matInput cdkFocusInitial name="Folder" placeholder="Enter new folder name" formControlName="folderName" class="e2e-folder-name">
        <mat-hint align="start">Please enter new folder name</mat-hint>
        <mat-error *ngFor="let validation of validationMessages">
          <mat-error *ngIf="createFolderDialog.get('folderName').hasError(validation.type) &&
                    (createFolderDialog.get('folderName').dirty ||
                    createFolderDialog.get('folderName').touched)" class="error-message">
            {{validation.message}}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions dir="rtl">
    <button mat-button color="primary" type="submit" (click)="onSubmit()" [disabled]="!createFolderDialog.valid">
      Create
    </button>
    <button mat-button color="primary" type="button" mat-dialog-close>
      Cancel
    </button>
  </mat-dialog-actions>
</div>
